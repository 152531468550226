<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Suppose an atom is located at these positions in a cubic unit cell. What fraction does each
        contribute to the contents of the cell? Justify your answers.
      </p>

      <p class="mb-5">
        <v-img style="max-width: 500px" src="/img/assignments/UofA/q1_2.png" />
      </p>

      <p class="mb-2">Center:</p>
      <p class="mb-n2">
        <s-textarea v-model="inputs.center" outlined />
      </p>

      <p class="mb-2">Face:</p>
      <p class="mb-n2">
        <s-textarea v-model="inputs.face" outlined />
      </p>

      <p class="mb-2">Edge:</p>
      <p class="mb-n2">
        <s-textarea v-model="inputs.edge" outlined />
      </p>

      <p class="mb-2">Corner:</p>
      <p class="mb-n2">
        <s-textarea v-model="inputs.corner" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUofA103SandboxQ1_2',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        center: null,
        face: null,
        edge: null,
        corner: null,
      },
    };
  },
};
</script>
